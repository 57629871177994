<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Kert.jpg"
                  alt="portfolio"
                />
              </div>

              <div class="port-single-thumb mt--30">
                <img
                  src="../../assets/img/567x600px/567x600px-Foldmunka.jpg"
                  alt="portfolio"
                />
              </div>


             
            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Zöld felület építése</h3>
                <p>
                  Mi minden esetben zöldhulladék lerakó telepre szállítjuk a zöldhulladékot, ahol a megfelelő technológia segítségével újrahasznosítják (aprítják, keverik, takarják) és kiváló minőségű érett komposztot készítenek belőle. Ezáltal csökkentjük a környezetünk terhelését. Éljünk környezettudatosan, hogy gyermekeinknek is élhető környezetet hagyunk magunk mögött. Már a kezdetektől neveljük gyermekeinket a környezettudatos szemléletmód alkalmazására. Tudatosítani kell bennük, hogy nem minden hulladék szemét, így a zöldhulladék sem az! 
                </p>
                <h4>Kertépítési munkák:</h4>
                <p>
                  A kertépítés minden esetben egyedi igényeket kíván. Minden helyszín és igény, változó tényezői befolyásolják annak kialakítását. Helyszíni felméréseink alkalmával, igyekszünk a Megrendelői elképzeléseket minél pontosabban megismerni és azt szakmai tapasztalatunkkal életre kelteni. Maga az építés egy összetett folyamat, hisz minden apró részletre ki kell térni a kivitelezés lehetőségeire, illetőleg akadályaira és előrelátóan kell meghatározni az igényeket. Ebben segítünk Önnek, javaslatokat teszünk a kialakításra, a növénytelepítésre és megvalósítjuk az elképzelését 
                </p>
                <h4>Hulladékgazdálkodás:</h4>
                <p></p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
